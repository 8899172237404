import React, { Component } from "react";
import styles from "../mainBannerCard/mainBannerCard.module.scss";

class MainBannerCard extends Component {
  props: {
    info?: any;
    isDefault?: boolean;
  };

  render() {
    const { isDefault } = this.props;
    if (isDefault)
      return (
        <a
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            height: "100%",
          }}
          target={"_blank"}
          href={
            "https://km.donorsearch.org/?utm_source=main_page&utm_medium=bon_marrow&utm_campaign=donor_search"
          }
        >
          <div className={styles.mainBannerCard} style={{ width: "100%" }}>
            <p className={styles.mainBannerCard_date}>Спецпроект</p>
            <p className={styles.mainBannerCard_title}>
              Всемирный день донора костного мозга
            </p>
            <span className={styles.mainBannerCard_btn}>Узнать подробнее</span>
            <img
              src="/static/images/mainBanner.png"
              className={styles.mainBannerCard_img_def_desktop}
              alt={""}
            />
            <img
              src="/static/images/human.svg"
              className={styles.mainBannerCard_img_default}
              alt={""}
            />
          </div>
        </a>
      );
    else {
      const {
        title,
        text,
        text_button,
        image,
        url,
        button_color,
        background,
        text_button_color,
        text_color,
        title_color,
        is_published,
      } = this.props.info;
      return (
        <a target={"_blank"} href={url} style={{ margin: "0 5px" }}>
          <div className={styles.mainBannerCard} style={{ background }}>
            {!is_published && (
              <span className={styles.mainBannerCardUnpublished}>
                Не опубликовано
              </span>
            )}
            <p
              className={styles.mainBannerCard_date}
              style={{ color: title_color }}
            >
              {title}
            </p>
            <p
              className={styles.mainBannerCard_title}
              style={{ color: text_color }}
            >
              {text}
            </p>
            <span
              className={styles.mainBannerCard_btn}
              style={{ background: button_color, color: text_button_color }}
            >
              {text_button}
            </span>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "100%",
                left: 0,
                right: 0,
              }}
            >
              <div style={{ position: "relative", height: "100%" }}>
                <img
                  src={image}
                  className={styles.mainBannerCard_img}
                  alt={""}
                />
              </div>
            </div>
          </div>
        </a>
      );
    }
  }
}

export default MainBannerCard;
